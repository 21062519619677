<template>
  <div class="list-note margin-content">
    <div class="dc-header">
      <b-container
        fluid
        class="d-flex align-items-center"
        style="padding-left: 0px"
      >
        <router-link :to="{ name: 'library' }">
          <div>コンテンツ</div>
        </router-link>
        <b-icon icon="chevronRight" class="mx-3"></b-icon>
        <div @click="getShowListChat()" style="cursor: pointer">
          チャット一覧
        </div>
        <b-icon
          icon="chevronRight"
          v-if="isShowDetailContent === 1"
          class="mx-3"
        ></b-icon>
        <div v-if="isShowDetailContent === 1">チャット</div>
      </b-container>
    </div>
    <div
      class="list-note-content"
      style="overflow: auto"
      v-if="isShowDetailContent === 0"
    >
      <div
        class="list-note-item my-2 position-relative customTitle"
        v-for="(item, index) in listChat.data"
        :key="index"
        @click="getDetailMessageByChatID(item)"
      >
        <div
          class="list-note-item-top my-2 d-flex align-items-center justify-content-between"
        >
          <div class="" style="word-break: break-all; padding-right: 3%">
            作成日:{{ moment(new Date(item.created_at)).format("YYYY/MM/DD") }}
          </div>
        </div>
        <div
          class="list-note-item-content d-flex"
          style="font-size: 16px; font-weight: bold"
        >
          <div class="row mx-0 w-100">
            <div class="col-3 col-md-2 px-1">
              <img
                v-if="item.image"
                :src="env + item.image"
                width="70px"
                height="70px"
              />
              <img
                v-else-if="item.image === null || item.image === ''"
                src="@/assets/img/no-image.jpg"
                width="70px"
                height="70px"
              />
            </div>
            <div class="col-4 col-md-3 px-0">
              <div class="mt-3">{{ item.title }}</div>
            </div>
            <div class="col-md-6 col-5 px-0">
              <div
                class="mt-3"
                style="
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                "
              >
                への{{ item.message }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="list-note-item-content d-flex customContentNoteMobile"
          style="word-break: break-all; font-size: 14px"
        ></div>
      </div>
      <div
        class="d-flex justify-content-center position-relative mt-4"
        v-if="listChat.total"
      >
        <CPagination
          v-if="listChat.total"
          :activePage.sync="page"
          :pages="listChat.last_page"
          align="center"
        />
      </div>
    </div>
    <div class="list-note-content" v-if="isShowDetailContent === 1">
      <div style="margin-bottom: 50px">
        <div
          class=""
          style="
            background-color: #668ad8b8;
            overflow: auto;
            height: calc(100vh - (140px + 111px));
            position: relative;
          "
          id="bodyChat"
        >
          <div
            class=""
            style="padding: 10px 12px"
            v-for="(itemMess, index) in listMessageByChatID.message"
            v-bind:key="index"
          >
            <!-- UI Message ADMIN -->
            <div
              v-if="itemMess.user_type !== 4 && itemMess.user_type !== 5"
              class="d-flex flex-nowrap align-items-center"
            >
              <div class="customChatAdmin mt-3">
                <div class="d-flex flex-column p-3">
                  <div style="word-wrap: break-word">
                    <div v-html="replaceMessage(itemMess.message)"></div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End UI Message ADMIN -->

            <!-- UI Message USER -->
            <div
              class="d-flex flex-nowrap flex-row-reverse align-items-center"
              v-if="itemMess.user_type === 4 || itemMess.user_type === 5"
            >
              <div class="customChatUser mt-3">
                <div class="d-flex flex-column p-3">
                  <div style="word-wrap: break-word">
                    <div v-html="replaceMessage(itemMess.message)"></div>
                  </div>
                </div>
              </div>
              <div
                class="pr-3 mt-3 del-mess"
                v-if="
                  itemMess.id !== listMessageByChatID.message[0].id &&
                  checkDelete != itemMess.id
                "
                @click="deletMessage(itemMess.id)"
              >
                <i class="fas fa-times" style="color: white"></i>
              </div>
            </div>
            <!-- End UI Message USER -->
          </div>
        </div>
        <div
          class="custom-control CustomInputChat w-100 pl-0"
          style="background-color: white; border-style: groove"
        >
          <div
            class="p-1 d-flex flex-nowrap justify-content-center align-items-center"
          >
            <textarea
              class="hoverMouse pl-0 px-4"
              v-model="titleSendMessage"
              placeholder="返信内容を記載"
              style="
                border: 1px solid #fff;
                height: 50px;
                width: 80%;
                background-color: #d8dbe0;
                border-radius: 30px;
                resize: none;
              "
              rows="3"
              no-resize
              @keyup="checkLimitLength"
              @keydown.enter.exact.prevent="titleSendMessage += '\n'"
              @keydown.enter.shift.exact.prevent="ClickSendMessage()"
            ></textarea>
            <div
              style="width: 20%"
              class="d-flex flex-column justify-content-center align-items-center"
            >
              <img
                v-on:click="ClickSendMessage()"
                src="@/assets/img/send.png"
                style="width: 18px; height: 20px; cursor: pointer"
              />
              <div>返信</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-alert-chat"
      modal-class=""
      style="min-width: 720px"
      hide-header
      hide-footer
    >
      <CRow>
        <CCol sm="12">
          <span>質問送信前に以下ご確認をお願いします。<br /></span>
          <span>・ノート内容は制作者側では見れません。<br /></span>
          <span>「質問」のみしか見れませんのでご留意ください。<br /></span>
          <span
            >※個別の回答ではなく、別のコンテンツやメルマガなどで回答されることがありますので<br
          /></span>
          <span>あらかじめご了承くださいませ。</span><br />
          <div class="w-100 mt-4 d-flex justify-content-center">
            <b-button
              v-on:click.prevent="applyAgree()"
              :disabled="isLoading"
              variant="info"
              class="m"
              >内容について同意し送信する
            </b-button>
          </div>
          <div class="remember-form mt-3">
            <input
              v-model="remember_me"
              type="checkbox"
              name="remember"
              id="remember"
              @change="readPopup"
            />
            <label class="ml-1" for="remember">ログインしたままにする</label>
          </div>
        </CCol>
      </CRow>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import { Constants } from "../../utils/constants";
export default {
  name: "listNoteShop",
  data() {
    return {
      dataNote: [],
      title: "",
      selectedTag: [],
      listTagOptions: [],
      isLoading: false,
      tag: "",
      page: 1,
      isAdded: 0,
      isShowDetailContent: 0,
      listNoteShopByContent: null,
      idContent: null,
      env: process.env.VUE_APP_ROOT_BACKEND + "/storage/",
      titleSendMessage: null,
      remember_me: null,
      isShowPopupRead: null,
      idChat: null,
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      checkDelete: null,
      previousTitleSendMessage: null,
    };
  },
  computed: {
    ...mapGetters([
      "message",
      "success",
      "error",
      "listChat",
      "listMessageByChatID",
    ]),
  },
  async created() {
    await this.getAllListChats(1);
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["success", false]);
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
    page() {
      this.getAllListChats(this.page);
    },
    listMessageByChatID() {
      this.idChat = this.listMessageByChatID.id;
      this.isShowPopupRead = this.listMessageByChatID.is_read;
    },
  },
  methods: {
    ...mapActions({
      getAllListChat: "getAllListChat",
      getMessageByChatID: "getMessageByChatID",
      readPopupChat: "readPopupChat",
      sendMessage: "sendMessage",
      deleteMessageByID: "deleteMessageByID",
    }),
    moment,
    async getAllListChats(page) {
      if (page === undefined) {
        page = this.page;
      }
      const formData = {
        page: page,
        data: {
          shop_id: parseInt(this.shop_id),
          limit: 10,
        },
      };
      await this.getAllListChat(formData);
    },
    async getDetailMessageByChatID(item) {
      this.isShowDetailContent = 1;
      this.idContent = item.content_id;
      const dataRequest = {
        idChat: item.id,
        shop_id: this.shop_id,
      };
      await this.getMessageByChatID(dataRequest);
    },
    readPopup() {
      const dataRequest = {
        shop_id: this.shop_id,
        content_id: this.idContent,
        is_read: 1,
      };
      this.readPopupChat(dataRequest);
    },
    async ClickSendMessage() {
      if (!this.titleSendMessage) {
        return;
      }
      if (this.isShowPopupRead === 0) {
        this.$bvModal.show("modal-alert-chat");
      } else {
        const data = {
          shop_id: this.shop_id,
          chat_id: this.idChat,
          message: this.titleSendMessage,
          user_id: localStorage.getItem(Constants.USER_ID),
        };
        const dataRepond = await this.sendMessage(data);
        if (dataRepond.success) {
          this.titleSendMessage = null;
          const dataRequest = {
            id: this.idChat,
          };
          await this.getDetailMessageByChatID(dataRequest);
          this.gotoBottom();
        }
      }
    },
    async applyAgree() {
      const data = {
        shop_id: this.shop_id,
        chat_id: this.idChat,
        message: this.titleSendMessage,
        user_id: localStorage.getItem(Constants.USER_ID),
      };
      const dataRepond = await this.sendMessage(data);
      if (dataRepond.success) {
        this.$bvModal.hide("modal-alert-chat");
        this.titleSendMessage = null;
        const dataRequest = {
          id: this.idChat,
        };
        await this.getDetailMessageByChatID(dataRequest);
      }
    },
    BackNoteAndChat(value) {
      this.isShowChat = value;
    },
    async deletMessage(id) {
      this.checkDelete = id;
      const dataRequest = {
        id: id,
        shop_id: this.shop_id,
      };
      const dataRepond = await this.deleteMessageByID(dataRequest);
      if (dataRepond.success) {
        this.titleSendMessage = null;
        const dataRequest = {
          id: this.idChat,
        };
        await this.getDetailMessageByChatID(dataRequest);
      }
    },
    async getShowListChat() {
      this.isShowDetailContent = 0;
      await this.getAllListChats(1);
    },
    gotoBottom() {
      var element = document.getElementById("bodyChat");
      element.scrollTop = element.scrollHeight - element.clientHeight;
    },
    checkLimitLength(event) {
      let newValue = event.target.value;
      if (newValue.length > Constants.LIMIT_MESSAGE) {
        event.preventDefault();
        this.titleSendMessage = this.previousTitleSendMessage;
        this.$store.commit("set", ["message", "メッセージが長すぎます"]);
        this.$store.commit("set", ["error", true]);
      } else {
        this.titleSendMessage = newValue;
        this.previousTitleSendMessage = newValue;
      }
    },
    replaceMessage(message) {
      let messageList = message.split("\n");
      let html = "";
      messageList.forEach((elem) => {
        if (elem.includes("http://") || elem.includes("https://")) {
          let elemList = elem.split(" ");
          elemList.forEach((val) => {
            if (val.includes("http://") || val.includes("https://")) {
              html += '<a href="' + val + '" target="_blank">' + val + "</a>";
            } else {
              html += "<div>" + val + "<div>";
            }
          });
        } else {
          html += "<div>" + elem + "<div>";
        }
      });
      return html;
    },
  },
};
</script>

<style lang="scss" scoped>
.list-note {
  padding: 0 50px;
  @media (max-width: 767px) {
    padding: 0 25px;
  }
  .dc-header {
    padding-top: 30px;
    font-size: 14px;
    a {
      color: #000;
      font-weight: 500;
      &:hover {
        text-decoration: none;
      }
    }
  }
  &-title {
    font-size: 24px;
    font-weight: 600;
  }
  &-content {
    margin-top: 30px;
    .title-content {
      font-size: 22px;
      color: #4a4a4a;
      margin: 10px 0;
    }
    .title-content-list {
      &-item {
        border: 2px solid #4a4a4a;
        border-radius: 20px;
        padding: 20px;
        margin-bottom: 30px;
        .header-item {
          padding: 0 50px 0 30px;
          &-title {
            font-size: 20px;
            color: #4a4a4a;
            font-weight: 600;
          }
          &-time {
            font-size: 16px;
            color: #000;
          }
        }
        .content-item {
          padding: 0 50px;
          margin: 20px 0;
          font-size: 14px;
        }
        .list-tags {
          &-item {
            border: 2px solid #af5a21;
            background: #ed7d30;
            color: white;
            padding: 5px 20px;
            border-radius: 10px;
            margin-left: 20px;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}
.list-tag {
  .item-tag {
    border: 2px solid #af5a21;
    background: #ed7d30;
    color: white;
    padding: 5px 20px;
    border-radius: 10px;
    margin-left: 20px;
    margin-bottom: 20px;
    cursor: pointer;
  }
}
.customA {
  background: #eee !important;
  border: 1px solid #eee !important;
  border-right: none !important;
  color: #1d1d1d !important;
  transition: all 0.3s ease !important;
}
.customA :hover {
  background: #eee;
  border-right: none;
  color: #1d1d1d;
  transition: all 0.3s ease;
  cursor: pointer;
}
.customA ::before {
  content: "";
  display: block;
  width: 18px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: 18px 20px;
  position: absolute;
  top: -1px;
  left: 100%;
  transition: 0.2s ease-out;
}
.customSpan {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 235px;
  display: block;
  height: 18px;
  line-height: 18px;
  font-family: "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ",
    Meiryo, "ＭＳ Ｐゴシック", sans-serif;
  word-break: break-all;
  box-sizing: border-box;
  font-size: 11px;
  padding: 0 0 0 10px;
}
.customContentNote {
  width: 95%;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
.customTag2 {
  background: #fff;
  border: 1px solid #eee;
  border-right: none;
  color: #1d1d1d;
  transition: all 0.3s ease;
}
.customTitle {
  cursor: pointer;
  border-bottom: 1px solid #d1d1d1;
  padding: 30px 0 30px 20px;
  background-image: url(../../assets/img/icon_arrow01.png);
  background-repeat: no-repeat;
  background-position: 95% center;
  background-size: 9px;
}
.customTitleMoblie {
  cursor: pointer;
  border: 1px solid #d1d1d1;
  padding: 15px 0 15px 15px;
  /* background-image: url(/img/icon_arrow01.939c2d50.png); */
  background-repeat: no-repeat;
  background-position: 95% center;
  background-size: 9px;
  border-radius: 20px;
  box-shadow: 0px 0px 11px 0px rgba(125, 125, 125, 0.75);
  -webkit-box-shadow: 0px 0px 11px 0px rgba(125, 125, 125, 0.75);
  -moz-box-shadow: 0px 0px 11px 0px rgba(125, 125, 125, 0.75);
}
.customBorder {
  border-top: 1px solid #d1d1d1;
}

html,
body {
  margin: 0;
  height: 100%;
  overflow: hidden;
}
.hoverMouse:hover {
  border: 1px solid #ccc !important;
}
.hoverMouse:focus {
  border: 1px solid #ccc !important;
  box-shadow: none;
}
.title2 {
  color: #7f7f7f;
  width: 100%;
  text-align: center;
  font-size: 16px;
  margin-top: 2em !important;
  margin-bottom: 2em !important;
  display: flex;
  align-items: center;
  font-family: "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ",
    Meiryo, "ＭＳ Ｐゴシック", sans-serif;
}
.title2::before,
.title2::after {
  border-top: 1px solid;
  content: "";
  flex-grow: 1;
  color: #dfdadadb;
}
.title2:before {
  margin-right: 2rem;
}
.title2:after {
  margin-left: 2rem;
}
/deep/ .modalCustom > .modal-dialog > .modal-content > .modal-body {
  height: 465px;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border-top: 1px solid;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
  border-color: #d8dbe0;
}
/deep/ .modalCustom > .modal-dialog > .modal-content > .modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 0.75rem;
  border-top: 1px solid;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
  border-color: #d8dbe0;
}
@media (max-width: 767px) {
  /deep/ .modalCustom > .modal-dialog {
    max-width: 100% !important;
    margin: 1.75rem auto;
  }
  /deep/ .modalCustom > .modal-dialog > .modal-content > .modal-body {
    width: 100%;
    overflow: auto;
    height: 400px;
  }
}
.customChatAdmin {
  width: 250px;
  border-radius: 25px;
  background-color: white;
}
.customChatUser {
  width: 250px;
  border-radius: 25px;
  background-color: white;
}
.remember-form {
  display: flex;
  justify-content: center;
  margin: 0 0 20px;
  align-items: center;
  label {
    font-size: 16px;
    color: #72777c;
    font-weight: bolder;
    margin: unset;
    cursor: pointer;
  }
  input {
    height: 20px;
    width: 20px;
  }
}
</style>
